// ChatInterface.js
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import MessageList from "./MessageList";
import InputArea from "./InputArea";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function ChatInterface({ authToken }) {
  const chatContext = useOutletContext();
  const { error, setError, aiModels } = chatContext;
  const { activeChatId } = useContext(AppContext);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
        setTimeout(() => setError(null), 300);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  return (
    <div className="relative flex flex-col h-full overflow-hidden">
      {error && (
        <div
          className={`transition-opacity duration-300 ease-in-out ${
            showError ? "opacity-100" : "opacity-0"
          } absolute z-20 w-full flex justify-center`}
          style={{ top: "3rem" }}
        >
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2 mx-auto max-w-full md:max-w-2xl"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        </div>
      )}

      {/*
        ヘッダー高さ相当のダミー
        (Header.js が fixed なら、ここで実際のヘッダーぶんだけの余白をとる)
      */}
      <div style={{ height: "0rem", flexShrink: 0 }}></div>

      {/*
        2. スマホで入力フォームが重ならないように
           paddingBottom に env(safe-area-inset-bottom) を足す
      */}
      <div
        className="
           flex-1
           overflow-y-auto
           overscroll-contain
           pt-4           /* = 3rem */
          pb-[8rem]       /* このあたりの下余白は変えずに */
          md:pt-2         /* = 2rem （md以上の画面幅でさらに小さく） */
         "
        style={{
          WebkitOverflowScrolling: "touch",
          overscrollBehavior: "contain",
        }}
      >
        <MessageList
          {...chatContext}
          activeChatId={activeChatId}
          aiModels={aiModels}
        />
      </div>

      <InputArea {...chatContext} aiModels={aiModels} />

      <Outlet context={{ ...chatContext, authToken }} />
    </div>
  );
}

export default ChatInterface;
