// Layout.js
import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import SideMenu from "./SideMenu";
import Header from "./Header";
import useChat from "../../hooks/useChat";
import TaskList from "./TaskList";

function Layout({ handleLogout, email, theme, setTheme }) {
  const initialWindowWidth = window.innerWidth;
  const [isTaskbarOpen, setIsTaskbarOpen] = useState(
    initialWindowWidth >= 1200
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    initialWindowWidth >= 1200
  );
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [aiModels, setAiModels] = useState([]);
  const chatContext = useChat();
  const sidebarRef = useRef(null);
  const [isPremium, setIsPremium] = useState(false);

  const {
    messages,
    pastChats,
    activeChatId,
    currentChatTitle,
    startNewChat,
    deleteChat,
    renameChat,
  } = chatContext;

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsSidebarOpen(false);
        setIsTaskbarOpen(false);
      } else {
        setIsSidebarOpen(true);
        setIsTaskbarOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ユーザープロフィール読み込み
  useEffect(() => {
    axios
      .get("/user/profile")
      .then(async (res) => {
        setTheme(res.data.theme || "system");
        if (res.data.profileImageUrl) {
          setProfileImageUrl(res.data.profileImageUrl);
        }
        let currentAiModels = res.data.aiModels || [];
        const userIsPremium =
          res.data.isPremium !== undefined ? res.data.isPremium : false;
        setIsPremium(userIsPremium);

        if (!userIsPremium) {
          // フリープランなら許可されていないモデルを除外
          const freeModels = [
            "claude-3-5-haiku-20241022",
            "gpt-4o-2024-08-06",
            "gemini-1.5-flash",
          ];
          currentAiModels = currentAiModels.filter((model) =>
            freeModels.includes(model)
          );
          try {
            await axios.put("/user/profile", { aiModels: currentAiModels });
          } catch (err) {
            console.error("Error updating user aiModels after downgrade:", err);
          }
        }

        setAiModels(currentAiModels);
      })
      .catch((err) => {
        console.error("Error fetching user profile:", err);
      });
  }, [setTheme]);

  return (
    <div className="h-screen flex flex-col bg-background dark:bg-background-dark overflow-hidden">
      {/* ---- ヘッダー ---- */}
      <Header
        currentChatTitle={currentChatTitle}
        setIsSidebarOpen={setIsSidebarOpen}
        isSidebarOpen={isSidebarOpen}
        setIsTaskbarOpen={setIsTaskbarOpen}
        isTaskbarOpen={isTaskbarOpen}
      />

      <div className="flex-1 flex relative overflow-hidden">
        <div
          ref={sidebarRef}
          className={`
            fixed top-0 left-0 h-full w-64
            bg-sidebarBackground dark:bg-sidebarBackground-dark
            border-r border-gray-200 dark:border-gray-700
            transform transition-transform duration-300
            ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
            z-50
          `}
        >
          <SideMenu
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            handleLogout={handleLogout}
            email={email}
            theme={theme}
            setTheme={setTheme}
            profileImageUrl={profileImageUrl}
            pastChats={pastChats}
            activeChatId={activeChatId}
            onChatSelect={(chatId) => navigate(`/chat/${chatId}`)}
            onChatDelete={deleteChat}
            onChatRename={renameChat}
            startNewChat={startNewChat}
            messages={messages}
            setAiModels={setAiModels}
            isPremium={isPremium}
            setIsPremium={setIsPremium}
          />
        </div>
        {/*
          右側タスクバーが表示されている場合に幅 w-64（=16rem）ぶんだけ
           マージンをとってチャット部分が隠れないようにする
        */}
        <div
          className="flex-1 flex flex-col min-h-0 overflow-hidden transition-all duration-300"
          style={{
            marginLeft: isSidebarOpen ? "16rem" : 0,
            marginRight: isTaskbarOpen ? "16rem" : 0,
          }}
          onClick={(e) => {
            // 画面が狭いときにサイドバー外をクリックしたら閉じる、等の処理
            if (window.innerWidth < 1200) {
              if (
                isSidebarOpen &&
                sidebarRef.current &&
                !sidebarRef.current.contains(e.target)
              ) {
                setIsSidebarOpen(false);
              }
              if (isTaskbarOpen) {
                setIsTaskbarOpen(false);
              }
            }
          }}
        >
          <Outlet context={{ ...chatContext, aiModels }} />
        </div>

        <div
          className={`
            fixed top-0 right-0 h-full w-64
            border-l border-gray-200 dark:border-gray-700
            transform transition-transform duration-300
            ${
              isTaskbarOpen
                ? // 開いているときはサイドバー色
                  "translate-x-0 bg-sidebarBackground dark:bg-sidebarBackground-dark z-20"
                : // 閉じているときはメイン背景色を被せる
                  "translate-x-full bg-background dark:bg-background-dark z-0"
            }
          `}
        >
          <TaskList
            isTaskbarOpen={isTaskbarOpen}
            setIsTaskbarOpen={setIsTaskbarOpen}
            activeChatId={chatContext.activeChatId}
            taskbarWidth={320}
          />
        </div>
      </div>
    </div>
  );
}

export default Layout;
