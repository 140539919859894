// MessageList.js
import React, { memo, useRef, useEffect, useState } from "react";
import MessageBubble from "./MessageBubble";
import isEqual from "lodash.isequal";

const MessageList = memo(
  ({
    messages,
    activeMessageIds,
    messageVersionState,
    setMessageVersionState,
    editingId,
    setEditingId,
    updateMessage,
    isResponding,
    setError,
    retryMessage,
    setActiveMessageId,
    navigateSiblingAtDepth,
    selectedModel,
    setSelectedModel,
    activeChatId,
    aiModels,
  }) => {
    const messagesContainerRef = useRef(null);
    const prevMessagesLengthRef = useRef(messages.length);
    const [isUserAtBottom, setIsUserAtBottom] = useState(true);
    const previousScrollTopRef = useRef(0);

    // 新しいメッセージが追加されたら最下部までスクロールする
    useEffect(() => {
      if (
        messagesContainerRef.current &&
        messages.length > prevMessagesLengthRef.current
      ) {
        const container = messagesContainerRef.current;
        const targetScroll = container.scrollHeight + 300;
        container.scrollTo({ top: targetScroll, behavior: "smooth" });
      }
      prevMessagesLengthRef.current = messages.length;
    }, [messages]);

    // チャットを切り替えたら一番上にリセット（必要に応じて自動スクロール先を変える）
    useEffect(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTo({
          top: 0,
          behavior: "auto",
        });
      }
    }, [activeChatId]);

    // ユーザーが一番下を見ているかどうか判定
    useEffect(() => {
      const container = messagesContainerRef.current;
      if (!container) return;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
        setIsUserAtBottom(isAtBottom);
        previousScrollTopRef.current = scrollTop;
      };

      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const renderMessages = () => {
      const messageChain = activeMessageIds
        .map((id) => messages.find((m) => m._id === id))
        .filter(Boolean);

      return messageChain.map((message, index) => (
        <div key={message._id} className="flex-1 overflow-auto py-2">
          <MessageBubble
            messageId={message._id}
            messages={messages}
            messageVersionState={messageVersionState}
            setMessageVersionState={setMessageVersionState}
            editingId={editingId}
            setEditingId={setEditingId}
            updateMessage={updateMessage}
            isResponding={isResponding}
            setError={setError}
            retryMessage={retryMessage}
            setActiveMessageId={setActiveMessageId}
            depth={index}
            navigateSiblingAtDepth={navigateSiblingAtDepth}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            messagesContainerRef={messagesContainerRef}
            isLastMessage={index === messageChain.length - 1}
            isUserAtBottom={isUserAtBottom}
            aiModels={aiModels}
          />
        </div>
      ));
    };

    return (
      <div
        ref={messagesContainerRef}
        className="
        w-full
        max-w-full md:max-w-2xl
        mx-auto
        overflow-x-auto
        pt-12  /* ヘッダー分の余白 (必要に応じてレスポンシブなどで調整) */
      "
      >
        {renderMessages()}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.activeMessageIds, nextProps.activeMessageIds) &&
      isEqual(prevProps.messages, nextProps.messages) &&
      prevProps.isResponding === nextProps.isResponding &&
      prevProps.editingId === nextProps.editingId &&
      isEqual(prevProps.messageVersionState, nextProps.messageVersionState)
    );
  }
);

export default MessageList;
